import Confetti from 'react-confetti';
import React from 'react';

const colors = [
  '#f44336',
  '#e91e63',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#CDDC39',
  '#FFEB3B',
  '#FFC107',
  '#f43f85',
  '#f43f85',
  '#f43f85',
];

export default (props) => {
  let options = {
    ...props,
    gravity: 0.1,
    recycle: true,
    colors,
  };

  if (props.fullscreen) {
    options = {
      ...options,
      height: window.innerHeight,
      width: window.innerWidth,
    };
  }

  return (
    <Confetti {...options} />
  );
};
