import React from 'react';
import _ from 'lodash';

import DashboardWidget from './DashboardWidget';

const renderAvatarRow = ({ id, image_url: imageUrl, name, actions }) => (
  <li key={id} className="tv-dashboard-widget--list__list-item">
    <div className="avatar">
      <img alt={`Avatar for ${name}`} className="avatar-image" src={imageUrl} />
    </div>

    <div className="tv-dashboard-widget--description">
      <span className="name">{name}</span>
      <span className="count">{actions}</span>
    </div>
  </li>
);

export default ({ activeTeamMembers }) => {
  const teamMembers = _
    .chain(activeTeamMembers)
    .sortBy('actions')
    .reverse()
    .take(9)
    .value();

  return (
    <DashboardWidget rows={2} title="Most active team members" className="tv-dashboard-widget--list tv-dashboard-widget--avatar-list">
      <ul className="tv-dashboard-widget--list">
        {teamMembers.map(renderAvatarRow)}
      </ul>
    </DashboardWidget>
  );
};
