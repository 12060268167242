import React from 'react';
// import history from 'react-router-dom';

export default class extends React.Component {
  componentWillMount() {
    window.location.assign("https://teamtailor.com/");
  }

  render() {
    return (
      <p>
        Redirecting to <a href="//teamtailor.com">teamtailor.com</a>
      </p>
    );
  }
}
