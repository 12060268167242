import React from 'react';
import Confetti from './Confetti';
import sizeMe from 'react-sizeme';

function renderTitle(title) {
  if (title === undefined) return;

  return (
    <header className="tv-dashboard-widget__header">
      <h1 className="tv-dashboard-widget__title">
        {title}
      </h1>
    </header>
  );
};

function columnClasses(columns) {
  // if (columns === 2) {
  //   return 'col-lg-4';
  // } else {
  //   return 'col-lg-2';
  // }
}

function rowClasses(rows) {
  // if (rows === 2) {
  //   return 'two-rows';
  // } else {
  //   return '';
  // }
}

const showConfetti = (show, size) => {
  if (show) {
    return <Confetti run={true} numberOfPieces={50} gravity={0.1} recycle={true} {...size} />;
  } else {
    return null;
  }
};

const DashboardWidget = ({ size, title, columns, rows, children, className, confetti }) => {
  const classes = `tv-dashboard-widget ${className || ""} ${columnClasses(columns)} ${rowClasses(rows)}`;

  return (
    <div className={classes}>
      {renderTitle(title)}
      <div className="tv-dashboard-widget__content">
        {children}
      </div>
      {showConfetti(confetti === true, size)}
    </div>
  );
};

export default sizeMe({
  monitorHeight: true,
  monitorWidth: true,
})(DashboardWidget);
