import React from 'react';
import _ from 'lodash';

import DashboardWidget from './DashboardWidget';

export default (props) => {
  const text = _.at(props, 'productUpdate.fields.text');
  const body = text ? <p>{text}</p> : null;

  return (
    <DashboardWidget className="product-update" title="Teamtailor updates 🎊">
      {body}
    </DashboardWidget>
  );
};
