import React from 'react';
import _ from 'lodash';

import DashboardWidget from './DashboardWidget';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDesktop, faTablet, faMobile } from '@fortawesome/free-solid-svg-icons'

const renderRow = (title, icon, percentage) => (
  <li className="tv-dashboard-widget--list__list-item">
    <div className="tv-dashboard-widget__visual">
      <FontAwesomeIcon size="2x" icon={icon} />
    </div>
    <div className="tv-dashboard-widget__description">
      <span className="tv-dashboard-widget--list__metric">{percentage}%</span>
      <span className="tv-dashboard-widget__label">{title}</span>
    </div>
  </li>
);

const calculatePercentage = (value, total) => {
  if (total > 0) {
    return Math.round(value / total * 100);
  } else {
    return null;
  }
};

export default ({ data }) => {
  const desktop = _.get(data, 'desktop') || 0;
  const tablet = _.get(data, 'tablet') || 0;
  const mobile = _.get(data, 'mobile') || 0;
  const total = desktop + mobile + tablet;

  return (
    <DashboardWidget columns={2} className="tv-dashboard-widget--list tv-dashboard-widget--list-horizontal tv-dashboard-widget--visual-list" title="Devices">
      <ul>
        {renderRow("Desktop", faDesktop, calculatePercentage(desktop, total))}
        {renderRow("Tablet", faTablet, calculatePercentage(tablet, total))}
        {renderRow("Mobile", faMobile, calculatePercentage(mobile, total))}
      </ul>
    </DashboardWidget>
  );
};
