import React from "react";

import logotype from "./logotype.svg";

import Metric from "./Metric";

import Date from "./Date";
import Team from "./Team";
import Jobs from "./Jobs";
import Devices from "./Devices";
import Visitors from "./Visitors";
import Promotions from "./Promotions";
import LatestHire, { BigSplash } from "./LatestHire";
import ProductUpdates from "./ProductUpdates";
import Sources from "./Sources";

export default ({ currentTime, result }) => {
  let visits = { value: 0, compareValue: 0 };

  if (result.visits) {
    visits = {
      value: result.visits.value.reduce((acc, b) => acc + b.sessions, 0),
      compareValue: result.visits.compareValue.reduce(
        (acc, b) => acc + b.sessions,
        0
      )
    };
  }

  const bodyClasses = [
    "body-wrap",
    result.showLatestHireSplash ? "body-wrap--blur" : ""
  ].join(" ");

  return (
    <div>
      <BigSplash {...result} />
      <div className={bodyClasses}>
        <div className="l-tv-dashboard">
          <div className="row">
            <div className="col-lg-2 cell">
              <Date currentTime={currentTime} />
            </div>

            <div className="col-lg-4 cell">
              <Visitors visits={result.visits || []} />
            </div>
            <div className="col-lg-2 cell">
              <Metric title="Visits" value={visits} label="Visits" />
            </div>
            <div className="col-lg-4 cell">
              <Devices data={result.devices} />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 cell">
              <Sources sources={result.sources} />
            </div>
            <div className="col-lg-2 cell">
              <Metric title="Connects" value={result.connects} label="Connects" />
            </div>

            <div className="col-lg-2 cell">
              <Metric
                title="Applications"
                value={result.applications}
                label="Applications"
              />
            </div>

            <div className="col-lg-2 cell">
              <Metric title="Hires" value={result.hires} label="Hires" />
            </div>

            <div className="col-lg-2 cell">
              <ProductUpdates productUpdate={result.latestProductUpdate} />
            </div>
          </div>

          <div className="row row--double">
            <div className="col-lg-8 u-flex u-flex-direction-col u-display-flex">
              <div className="row u-flex">
                <div className="col-lg-3 cell">
                  <LatestHire
                    {...result.latestHire}
                    showLatestHireSplash={result.showLatestHireSplash}
                  />
                </div>

                <div className="col-lg-3 cell">
                  <Metric
                    title="Active employees"
                    value={result.activeEmployees}
                    label="Employees"
                  />
                </div>

                <div className="col-lg-3 cell">
                  <Metric
                    title="Team activity"
                    value={result.employeeActivities}
                    label="Actions"
                  />
                </div>

                <div className="col-lg-3 cell">
                  <Promotions promotions={result.promotions} />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 cell">
                  <Jobs popularJobs={result.jobActivity} />
                </div>

                <div className="col-lg-3 cell">
                  <Metric
                    lowerIsBetter
                    title="Time to reject"
                    value={result.timeToReject}
                    label="Days"
                  />
                </div>

                <div className="col-lg-3 cell">
                  <Metric
                    lowerIsBetter
                    title="Time to hire"
                    value={result.timeToHire}
                    label="Days"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-2 u-flex u-display-flex">
              <div className="row u-flex">
                <div className="col-lg-12 cell">
                  <Team activeTeamMembers={result.mostActiveEmployees} />
                </div>
              </div>
            </div>

            <div className="col-lg-2 u-flex u-flex-direction-col u-display-flex">
              <div className="row u-flex">
                <div className="col-lg-12 cell">
                  <Metric
                    lowerIsBetter
                    title="Time in inbox"
                    value={result.timeToInbox}
                    label="Days"
                  />
                </div>
              </div>

              <div className="row u-flex">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="logo-wrap">
        <div className="u-flex logo-wrap__last-30-days">
          <h3>Hiring dashboard</h3>
          <span>Last 30 days</span>
        </div>
        <img src={logotype} alt="Teamtailor logo" />
      </div>
    </div>
  );
};
