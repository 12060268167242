import React from 'react';
import _ from 'lodash';

import DashboardWidget from './DashboardWidget';
import EmptyState from './EmptyState';

const Jobs = ({ jobs }) => {
  const topJobs = _
    .chain(jobs)
    .sortBy(row => row.applications)
    .reverse()
    .take(4)
    .value();

  const totals = _.chain(topJobs).map(row => row.applications).sum().value();

  const items = topJobs.map(row => {
    const percentage = Math.round((row.applications / totals) * 100);

    return (
      <div key={row.job_id} className="horizontal-bar__item">
        <div style={{width: `${percentage}%`}} className="horizontal-bar__item--filled" />
        <span className="horizontal-bar__label">
          <span>{row.title}</span>
          <div className="u-flex" />
          <b>{row.applications}</b>
        </span>
      </div>
    );
  });

  return (
    <div className="horizontal-bar">
      {items}
    </div>
  );
};

export default ({ popularJobs }) => {
  return (
    <DashboardWidget columns={2} className="tv-dashboard-widget--list tv-dashboard-widget--text-list" title="Popular jobs">
      {_.isEmpty(popularJobs) ? <EmptyState /> : <Jobs jobs={popularJobs} />}
    </DashboardWidget>
  );
};
