import React from 'react';
import moment from 'moment';
import cloudinary from 'cloudinary-core';
import Confetti from './Confetti';

import DashboardWidget from './DashboardWidget';

const remoteImageUrl = (imageUrl) => {
  const cl = new cloudinary.Cloudinary({ cloud_name: "teamtailor", secure: true });
  return cl.url(imageUrl, {
    type: 'upload',
    crop: 'thumb',
    gravity: 'face:center',
    width: 80,
    height: 80,
  });
};

const Avatar = ({ avatar_url: imageUrl, name, initials, color }) => {
  if (imageUrl === null) {
    const style = { backgroundColor: color };
    return (
      <div className="tv-dashboard-widget--latest-hire__avatar" style={style}>
        {initials}
      </div>
    );
  } else {
    return <img className="tv-dashboard-widget--latest-hire__avatar" src={remoteImageUrl(imageUrl)} alt={name} />;
  }
};

export const BigSplash = (props) => {
  const timeWidth = (props.secondsLeftOfHireSplash / props.lengthOfSplash) * 100;
  const splashClasses = ['tv-dashboard-splash', props.showLatestHireSplash ? 'tv-dashboard-splash--show' : ''].join(' ');

  if (props.latestHire == null) {
    return null;
  } else {
    return (
      <div className={splashClasses}>
        <div className="tv-dashboard-splash__radial">
          <div className="tv-dashboard-splash__content">
            <h1 className="tv-dashboard-widget--latest-hire__new-hire">NEW HIRE!</h1>
            <Avatar {...props.latestHire} />
            <h1 className="tv-dashboard-widget--latest-hire__name">{props.latestHire.name}</h1>
            <h2 className="tv-dashboard-widget--latest-hire__job-title">{props.latestHire.job_title}</h2>
          </div>
          <Confetti run={true} fullscreen="true" numberOfPieces={200} />
        </div>
        <div className="tv-dashboard-splash__timer" style={{width: `${timeWidth}%`}} />
      </div>
    );
  }
};

export default (props) => {
  const { timestamp, name, job_title: jobTitle } = props;

  if (timestamp) {
    const hiredToday = moment(timestamp).isSame(moment(), 'd');

    return (
      <DashboardWidget confetti={hiredToday && !props.showLatestHireSplash} className="tv-dashboard-widget--latest-hire" title="Latest hire">
        <Avatar {...props} />
        <h1 className="tv-dashboard-widget--latest-hire__name">{name}</h1>
        <h2 className="tv-dashboard-widget--latest-hire__job-title">{jobTitle}</h2>
      </DashboardWidget>
    );
  } else {
    return (
      <DashboardWidget className="tv-dashboard-widget--latest-hire" title="Latest hire">
        <span aria-label="Sad face" role="img">😢</span>
        <div>No hires lately</div>
      </DashboardWidget>
    );
  }
};
