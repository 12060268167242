import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';

import DashboardWidget from './DashboardWidget';

export default class extends React.Component {
  calculatePercentage() {
    if (!this.props.value) return null;

    const { value, compareValue } = this.props.value;
    return Math.floor(value / compareValue * 100) - 100;
  }

  formatValue(value) {
    if (value > 1000) {
      return `${(value / 1000).toFixed(1)}K`;
    } else {
      return value;
    }
  }

  renderPercentage() {
    const percentage = this.calculatePercentage();
    if (percentage === Infinity) return;

    if (this.props.lowerIsBetter) {
      if (percentage < 0) {
        return (
          <span className="tv-dashboard-widget__compare tv-dashboard-widget__compare--positive">
          <FontAwesomeIcon icon={faArrowDown} /> {percentage}%
          </span>
        );
      } else if (percentage > 0) {
        return (
          <span className="tv-dashboard-widget__compare tv-dashboard-widget__compare--negative">
          <FontAwesomeIcon icon={faArrowUp} /> {percentage}%
          </span>
        );
      }
    } else {
      if (percentage > 0) {
        return (
          <span className="tv-dashboard-widget__compare tv-dashboard-widget__compare--positive">
          <FontAwesomeIcon icon={faArrowUp} /> {percentage}%
          </span>
        );
      } else if (percentage < 0) {
        return (
          <span className="tv-dashboard-widget__compare tv-dashboard-widget__compare--negative">
          <FontAwesomeIcon icon={faArrowDown} /> {percentage}%
          </span>
        );
      }
    }
  }

  render() {
    const { title, label, className } = this.props;
    let value = this.props.value ? this.props.value.value : 0;

    return (
      <DashboardWidget
        title={title}
        className={`tv-dashboard-widget--metric ${className || ''}`}
      >
        <div className="tv-dashboard-widget__metric-container">
          <span className="metric__metric">
            <CountUp duration={1} formattingFn={this.formatValue} start={0} end={value} />
          </span>
          {this.renderPercentage()}
        </div>
        <span className="tv-dashboard-widget__label">{label}</span>
      </DashboardWidget>
    );
  }
}
