import * as Sentry from '@sentry/browser';
import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import './App.scss';

import RedirectToTeamtailor from './RedirectToTeamtailor';
import DashboardContainer from './containers/Dashboard';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
   dsn: process.env.REACT_APP_SENTRY_DSN,
  });
}

class AppRouter extends React.Component {
  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/d/:uuid" exact component={DashboardContainer} />
          <Route to="/" component={RedirectToTeamtailor} />
        </Switch>
      </Router>
    );
  }
};


export default AppRouter;
