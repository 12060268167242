import moment from 'moment';
import _ from 'lodash';

const API_ENDPOINT = process.env.REACT_APP_TEAMTAILOR_API_HOST;

function serialize(params) {
  var str = [];
  for (var p in params) {
    if (params.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(params[p]));
    }
  }

    return str.join("&");
  }

export default class Analytics {
  constructor(token) {
    this.headers = {
      "Content-Type": "application/vnd.api+json",
      "Authorization": `Token token=${token}`,
      "X-Api-Version": "20161108",
    }
  }
  
  async fetchChannelName() {
    const url = `${API_ENDPOINT}/v1/dashboard_auth`;

    return await fetch(url, { headers: this.headers })
      .then(response => response.json())
  }

  async fetchPromotions() {
    return await this._makeRequest('promotions', this._currentPeriod());
  };

  async fetchSources() {
    return await this._makeRequest('sources', this._currentPeriod());
  };

  async fetchVisits() {
    const { visits: value } = await this._makeRequest('visits', this._currentPeriod());
    const { visits: compareValue } = await this._makeRequest('visits', this._previousPeriod());
    return { visits: { value, compareValue }};
  };

  async fetchDevices() {
    return await this._makeRequest('devices', this._currentPeriod())
      .then(({ devices }) => ({
        devices: devices.reduce((acc, d) => Object.assign({}, acc, { [d.deviceCategory]: d.sessions }), {}),
      }));
  }

  async fetchDemographics() {
    return await this._makeRequest('demographics', this._currentPeriod())
      .then(result => {
        return {
          demographics: result.demographics.reduce((acc, a) => Object.assign({}, acc, { [a.userGender]: a.sessions }), {})
        };
      });
  }

  async fetchStageTimings() {
    const result = await this._makeRequest('stage_timings', this._currentPeriod());
    const compareResult = await this._makeRequest('stage_timings', this._previousPeriod())

    return {
      timeToInbox: {
        value: moment.duration({ seconds: result.time_in_inbox }).asDays(),
        compareValue: moment.duration({ seconds: compareResult.time_in_inbox }).asDays(),
      },
      timeToReject: {
        value: moment.duration({ seconds: result.time_to_reject }).asDays(),
        compareValue: moment.duration({ seconds: compareResult.time_to_reject }).asDays(),
      },
      timeToHire: {
        value: moment.duration({ seconds: result.time_to_hired }).asDays(),
        compareValue: moment.duration({ seconds: compareResult.time_to_hired }).asDays(),
      },
    }
  };

  async fetchJobActivities() {
    return await this._makeRequest('job_activities', this._currentPeriod())
      .then(({ job_activities }) => ({ jobActivity: job_activities }));
  }

  async fetchConnects() {
    const { result: value } = await this._makeRequest('connect_counts', this._currentPeriod())
    const { result: compareValue } = await this._makeRequest('connect_counts', this._previousPeriod())

    return { connects: { value, compareValue } };
  }

  async fetchApplications() {
    const { result: value } = await this._makeRequest('application_counts', this._currentPeriod())
    const { result: compareValue } = await this._makeRequest('application_counts', this._previousPeriod())

    return { applications: { value, compareValue } };
  }

  async fetchHires() {
    const { hires: result } = await this._makeRequest('hires', this._currentPeriod())
    const { hires: compareResult } = await this._makeRequest('hires', this._previousPeriod())
    const latestHire = _.chain(result).sortBy('timestamp').reverse().first().value();

    return {
      latestHire,
      hires: {
        value: result.length,
        compareValue: compareResult.length,
      }
    };
  }

  async fetchEmployeeActivities() {
    return await this._makeRequest('employee_activities', this._currentPeriod())
      .then(({ employee_activities }) => {
        const employeeWithActions = _.map(employee_activities, row => {
          const actions = _.sum([row.messages, row.moves, row.notes, row.rejected, row.hired, row.reviewed, row.sourced, row.scored]);
          return { actions, ...row };
        });

        return {
          employeeActivities: { value: _.chain(employeeWithActions).map('actions').sum().value() },
          mostActiveEmployees: employeeWithActions,
          activeEmployees: { value: _.uniqBy(employee_activities, 'id').length },
        };
      });
  }

  _currentPeriod() {
    return {
      end_date: moment().format('YYYY-MM-DD'),
      start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    };
  }

  _previousPeriod() {
    return {
      start_date: moment().subtract(60, 'days').format('YYYY-MM-DD'),
      end_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
    };
  }

  async _makeRequest(path, params = {}) {
    const serializedParams = serialize(params);
    const url = `${API_ENDPOINT}/v1/analytics/${path}?${serializedParams}`;

    return await fetch(url, { headers: this.headers })
      .then(response => response.json())
  }
};
