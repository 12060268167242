import React from 'react';
import _ from 'lodash';

import EmptyState from './EmptyState';
import DashboardWidget from './DashboardWidget';

const Promotions = ({ promotions }) => {
  const sortedPromotions = _
    .chain(promotions)
    .sortBy(row => row.applications.length)
    .reverse()
    .take(4)
    .value();

  const totals = _.chain(sortedPromotions).flatMap(row => row.applications).value().length;

  const items = sortedPromotions.map(row => {
    const percentage = Math.round((row.applications.length / totals) * 100);
    const name = row.name.replace(/Trackable s/, 'S')

    return (
      <div key={row.name} className="horizontal-bar__item">
        <div style={{width: `${percentage}%`}} className="horizontal-bar__item--filled" />
        <span className="horizontal-bar__label"><b>{percentage}%</b>&nbsp; {name}</span>
      </div>
    );
  });

  return (
    <div className="horizontal-bar">
      {items}
    </div>
  );
};

export default ({ promotions }) => {
  return (
    <DashboardWidget title="Top promotions">
      {_.isEmpty(promotions) ? <EmptyState /> : <Promotions promotions={promotions} />}
    </DashboardWidget>
  );
};
