import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import Pusher from 'pusher-js';

import Dashboard from '../components/Dashboard/index';
import AnalyticsApi from '../api/Analytics';

const contentful = require('contentful');

const SHOW_HIRE_SPLASH_SECONDS = 120;

export default class extends React.Component {
  constructor() {
    super(...arguments);

    this.analyticsClient = new AnalyticsApi(this.props.match.params.uuid);
    this.pusherClient = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      authEndpoint: `${process.env.REACT_APP_TEAMTAILOR_API_HOST}/v1/dashboard_auth`,
      auth: {
        headers: this.analyticsClient.headers,
      },
    });
    this.contentfulClient = contentful.createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
    });

    this.analyticsClient.fetchChannelName().then(({ channel_name: channelName }) => {
      this.dashboardChannel = this.pusherClient.subscribe(channelName);
      this.dashboardChannel.bind('new-hire', this.handleNewHireEvent.bind(this));
    });

    this.state = {
      applications: null,
      connects: null,
      devices: null,
      employeeActivities: null,
      hires: null,
      jobActivity: null,
      promotions: null,
      timeToInbox: null,
      timeToReject: null,
      timeToHired: null,
      visits: null,
      sources: null,
      latestProductUpdate: null,
      latestHire: null,
      showLatestHireSplash: false,

      currentTime: new Date(),
    };
  }

  handleNewHireEvent(latestHire) {
    this.setState({ latestHire });
  }

  updateDashboardData() {
    this.analyticsClient.fetchPromotions().then(result => this.setState(result));
    this.analyticsClient.fetchVisits().then(result => this.setState(result));
    this.analyticsClient.fetchDevices().then(result => this.setState(result));
    this.analyticsClient.fetchStageTimings().then(result => this.setState(result));
    this.analyticsClient.fetchJobActivities().then(result => this.setState(result));
    this.analyticsClient.fetchConnects().then(result => this.setState(result));
    this.analyticsClient.fetchApplications().then(result => this.setState(result));
    this.analyticsClient.fetchHires().then(result => this.setState(result));
    this.analyticsClient.fetchEmployeeActivities().then(result => this.setState(result));
    this.analyticsClient.fetchSources().then(result => this.setState(result));

    this.contentfulClient
      .getEntries({ content_type: 'puff', 'fields.type': 'update', order: '-sys.createdAt' })
      .then(({ items }) => this.setState({ latestProductUpdate: items[0] }));
  }

  updateCurrentTime() {
    let nextState = { currentTime: new Date(), showLatestHireSplash: false };
    const latestHireTimestamp = _.get(this.state, 'latestHire.timestamp');

    if (latestHireTimestamp) {
      const secondsSinceLastHire = moment.duration(moment().diff(moment(latestHireTimestamp))).as('seconds');

      nextState = {
        ...nextState,
        showLatestHireSplash: secondsSinceLastHire < SHOW_HIRE_SPLASH_SECONDS,
        secondsLeftOfHireSplash: SHOW_HIRE_SPLASH_SECONDS - secondsSinceLastHire,
        lengthOfSplash: SHOW_HIRE_SPLASH_SECONDS,
      };
    }

    this.setState(nextState);
  }

  componentDidMount() {
    this.updateDashboardData();

    this.clockIntervalId = setInterval(this.updateCurrentTime.bind(this), 1000);
    this.dashboardIntervalId = setInterval(this.updateDashboardData.bind(this), 1000 * 60 * 10);
  }

  componentWillDestroy() {
    window.clearInterval(this.clockIntervalId);
    window.clearInterval(this.updateDashboardData);
  }

  render() {
    return <Dashboard currentTime={this.state.currentTime} result={this.state} />;
  }
}
