import React from 'react';
import DashboardWidget from './DashboardWidget';
import moment from 'moment';

export default ({ currentTime }) => {
  const date = moment(currentTime);

  return (
    <DashboardWidget className="sticky-top-left  tv-dashboard-widget--clock ">
      <div className="u-flex" />
      <span className="clock__clock">{date.format('LT')}</span>
      <div className="clock__date-wrap">
        <span className="clock__date">{date.format('LL')}</span>
      </div>
      <div className="u-flex" />
    </DashboardWidget>
  );
};
