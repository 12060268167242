import React from 'react';
import _ from 'lodash';

import DashboardWidget from './DashboardWidget';
import EmptyState from './EmptyState';

const Sources = ({ sources }) => {
  const sortedPromotions = _
    .chain(sources)
    .reject(row => row.applications === 0)
    .sortBy(row => row.applications)
    .reverse()
    .take(4)
    .value();

  const totals = _.chain(sortedPromotions).map(row => row.applications).sum().value();

  const items = sortedPromotions.map(row => {
    const percentage = Math.round((row.applications / totals) * 100);

    return (
      <div key={row.source} className="horizontal-bar__item">
        <div style={{width: `${percentage}%`}} className="horizontal-bar__item--filled" />
        <span className="horizontal-bar__label"><b>{percentage}%</b>&nbsp;{row.source}</span>
      </div>
    );
  });

  if (_.isEmpty(sortedPromotions)) {
    return <EmptyState />;
  } else {
    return (
      <div className="horizontal-bar">
      {items}
      </div>
    );
  }
};

export default ({ sources }) => {
  return (
    <DashboardWidget columns={2} title="Top Converting Sources">
      {sources ? <Sources sources={sources} /> : null}
    </DashboardWidget>
  );
};
