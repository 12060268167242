import React from 'react';
import { Line } from 'react-chartjs-2';
import sizeMe from 'react-sizeme';

import DashboardWidget from './DashboardWidget';

class Visitors extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return JSON.stringify(this.props.visits) !== JSON.stringify(nextProps.visits);
  }

  render() {
    const { visits, size } = this.props;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext("2d");
    const gradient = context.createLinearGradient(0, 350, 0, 0);
    gradient.addColorStop(0, "#f53884");
    gradient.addColorStop(1, "#ff85b7");

    const data = {
      labels: visits.value.map(row => row.date),
      datasets: [
        {
          data: visits.value.map(row => row.sessions),
          backgroundColor: gradient,
          borderWidth: 0,
          fill: true,
          pointRadius: 0,
        },
      ],
    };

    const options = {
      animation: {
        duration: 1000,
      },
      legend: { display: false },
      tooltips: {
        enabled: false,
      },
      scales: {
        yAxes: [{ ticks: { fontColor: 'white'} }],
        xAxes: [{
          ticks: { display: false },
        }],
      },
      title: {
        display: false,
      },
    };

    const line = {
      ...size,
      data,
      options: {
        maintainAspectRatio: false,
        ...options,
      },
    };

    if (size.height) {
      return (
        <Line {...line} />
      );
    } else {
      return null;
    }
  }
}

const VisitorsWithSize = sizeMe({ monitorHeight: true, monitorWidth: true })(Visitors);

export default ({ visits }) => (
  <DashboardWidget columns={2} title="Visits">
    {visits.value ? <VisitorsWithSize visits={visits} /> : null}
  </DashboardWidget>
);
